import React, { useMemo } from 'react';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivateHospitalData } from 'selectors/privateHospitalData';
import { getCurrentUser } from 'selectors/users';
import { useTrackPage } from './Analytics/useAnalytics';
import { TrackPage } from './Analytics/Track';
import defaultOgImage from '../assets/images/meta/share-image_general.jpg';

const UsePageData = React.memo(({ pageObj }) => {
  const dispatch = useDispatch();
  const privateHospitalData = useSelector(getPrivateHospitalData);
  let currentUser = { isSet: false };
  currentUser = useSelector(getCurrentUser);
  const { lastLocation } = window;

  useTrackPage(() => {
    if (pageObj) {
      if (lastLocation) {
        pageObj.pageProperty.referrer = window.location.origin + lastLocation;
      }
      TrackPage(
        {
          ...pageObj,
          privateHospitalData,
          affiliation: currentUser.affiliation,
          userId: currentUser?.id,
        },
        dispatch
      );
    }
  }, [dispatch, pageObj, currentUser?.isSet]);
  return null;
});

UsePageData.propTypes = {
  pageObj: propTypes.object,
};

const HelmetContent = React.memo(
  ({
    pageName,
    title,
    description,
    ogImage = defaultOgImage,
    canonicalURL,
    seo = {},
    type = 'website',
    mergeData = {},
    ogTitle,
    ogDescription,
    disableIndex,
    pageCategory,
    prerenderStatusCode,
  }) => {
    // for avoiding object reference change on every rerender
    const memoizedPageObj = useMemo(
      () => ({
        pageName,
        pageCategory,
        pageProperty: { title },
        ...mergeData,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [pageName, pageCategory, title, ...Object.values(mergeData)]
    );
    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta content={type} name='og:type' />
          <meta content={description} name='description' />
          <meta
            content={ogDescription || description}
            property='og:description'
          />
          <meta content={ogTitle || title} property='og:title' />
          <meta content={ogImage} property='og:image' />
          {prerenderStatusCode && (
            <meta name='prerender-status-code' content={prerenderStatusCode} />
          )}
          {canonicalURL && <link rel='canonical' href={canonicalURL} />}
          {Object.keys(seo).map((val) => (
            <link rel={val} href={seo[val]} key={seo[val]} />
          ))}
          {disableIndex && <meta name='robots' content='noindex' />}
        </Helmet>
        <UsePageData pageObj={memoizedPageObj} />
      </>
    );
  }
);

HelmetContent.propTypes = {
  pageName: propTypes.string,
  title: propTypes.string,
  ogTitle: propTypes.string,
  description: propTypes.string,
  ogDescription: propTypes.string,
  ogImage: propTypes.string,
  canonicalURL: propTypes.string,
  type: propTypes.string,
  seo: propTypes.object,
  mergeData: propTypes.object,
  prerenderStatusCode: propTypes.string,
};

export default HelmetContent;
