import React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import themes from '../Themes';

const { Title: Component } = Typography;

const defaultColor = 'indigo';
const defaultFontFamily = 'aesthet-nova';

const levels = {
  1: { fontSize: 60, lineHeight: 72, fontWeight: 'normal' },
  2: { fontSize: 40, lineHeight: 56, fontWeight: 'normal' },
  3: { fontSize: 30, lineHeight: 40, fontWeight: 'normal' },
  4: { fontSize: 28, lineHeight: 40, fontWeight: 800 },
  5: { fontSize: 22, lineHeight: 30, fontWeight: 800 },
  6: { fontSize: 20, lineHeight: 30, fontWeight: 500 },
};

const fontFamilies = {
  [defaultFontFamily]: themes['font-serif-medium'],
  heavy: themes['font-sans-bold'],
  medium: themes['font-sans-medium'],
};

const colors = {
  secondary: themes.I60,
  grey: themes.I90,
  [defaultColor]: themes.indigo,
  white: themes.white,
  astral: themes.astral,
  mdsaveBlue: themes['mdsave-blue'],
};

const TitleComponent = styled(
  ({ color, fontFamily, uppercase, capitalize, ...props }) => (
    <Component {...props} />
  )
)`
  && {
    margin: 0;
    font-family: ${(props) => fontFamilies[props.fontFamily]};
    font-size: ${(props) => levels[props.level].fontSize}px;
    line-height: ${(props) => levels[props.level].lineHeight}px;
    font-weight: ${(props) => levels[props.level].fontWeight};
    color: ${(props) => colors[props.color]};
    ${(props) =>
      props.uppercase &&
      css`
        text-transform: uppercase;
      `};
    ${(props) =>
      props.capitalize &&
      css`
        text-transform: capitalize;
      `};
  }
`;

// in order to make sure that each level renders  proper h tag, as well  as to make sure that level 6 renders h6
const Title = ({ ...props }) => (
  <TitleComponent as={`h${props.level}`} {...props} />
);

Title.propTypes = {
  /** title level */
  level: PropTypes.oneOf(Object.keys(levels).map(Number)),
  fontFamily: PropTypes.oneOf(Object.keys(fontFamilies)),
  color: PropTypes.oneOf(Object.keys(colors)),
};

Title.defaultProps = {
  level: 2,
  fontFamily: defaultFontFamily,
  color: defaultColor,
};

/** @component */
export default Title;
