import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'md-styled-components';
import { Button } from 'md-styled-components-v2';
import { getAlertData } from 'selectors/alertData';
import { useSelector, useDispatch } from 'react-redux';
import { cleanAlertData } from 'actions/alertData';
import { useAlert } from '../../contexts/AlertProvider';
import { user } from '../../constants';
import errors from '../../utils/messages/errors';

const ResendRecoveryLink = lazy(() => import('./ResendRecoveryLink'));

const { Text } = Typography;

export default () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const clearAlertData = useCallback(() => {
    dispatch(cleanAlertData());
  }, []);
  const { warning, close } = useAlert();
  const alertData = useSelector(getAlertData);

  const redirectToForgotPassword = () => {
    navigate('forgotPassword');
  };

  const toPasswordRecovery = useCallback(
    (redirectTo = redirectToForgotPassword) => {
      close();
      clearAlertData();
      redirectTo();
    },
    [close, clearAlertData]
  );

  useEffect(() => {
    if (alertData) {
      switch (true) {
        case !!alertData.recoveryEmail:
          setRecoveryEmail(alertData.recoveryEmail);
          break;
        case !!alertData.expiredActivation || !!alertData.invalidActivation:
          warning({
            title: 'Your Activation Link Has Expired',
            html: (
              <Text color='grey'>
                {alertData.invalidActivation && (
                  <>Please check your email for the new activation link.</>
                )}
                {!alertData.invalidActivation && (
                  <>
                    Please check your inbox for a new activation link and click{' '}
                    <i>
                      {+alertData.expiredActivation === user.GROUP_USER
                        ? ' Activate My Account'
                        : ' Confirm Email'}
                    </i>{' '}
                    to proceed.
                  </>
                )}
              </Text>
            ),
            onCancel: clearAlertData,
            closable: true,
          });
          break;
        case !!alertData.invalidUser:
          warning({
            title: 'Your Password Link Has Expired',
            html: (
              <Text color='grey'>
                This password reset link has expired.{' '}
                <Button type='link' onClick={() => toPasswordRecovery()}>
                  Click here
                </Button>{' '}
                to reset your password.
              </Text>
            ),
            onCancel: clearAlertData,
            closable: true,
          });
          break;
        case !!alertData.notExistingUser:
          warning({
            html: errors[alertData.message](() =>
              toPasswordRecovery(() => {
                window.location.href = '/contactus';
              })
            ),
            onCancel: clearAlertData,
            closable: true,
          });
          break;
        default:
      }
    }
  }, [warning, clearAlertData, toPasswordRecovery, alertData]);

  return (
    <Suspense fallback={<></>}>
      {recoveryEmail && (
        <ResendRecoveryLink
          recoveryEmail={recoveryEmail}
          warning={warning}
          close={close}
          clearAlertData={clearAlertData}
        />
      )}
    </Suspense>
  );
};
