/**
 * Utility functions for managing checkout form interactions, session storage,
 * validation, and tracking.
 *
 * This module includes functions for handling credit card options, sending checkout requests,
 * validating form data, managing session storage, and tracking user actions.
 */

import client from 'services/client';
import { getSesStorageItem, setSesStorageItem } from 'utils/storage';
import { sesStorage } from 'constants/index';
import { ZIP_CODE_LENGTH } from 'constants/address';
import { useAlert } from 'contexts/AlertProvider';
import { getCityStateByZip } from 'services/location';
import { Track } from 'components/Analytics/Track';
import dayjs from 'dayjs';
import { scrollTo } from 'utils/common';
import { showNumber } from 'hooks/PhoneNumber';
import {
  B_DAY,
  B_MONTH,
  B_YEAR,
  EMAIL_ADDRESS,
  FIRST_NAME,
  LAST_NAME,
  NO_EMAIL,
  SELECTED_CREDIT_CARD,
  PAYMENT_SYSTEM,
  DISCOUNT_CODE,
  USERNAME,
  PASSWORD,
  MOBILE_PHONE,
  SERVICE_DATE,
  COUPON,
} from '../_constants/fieldNames';
import { SET_INITIAL_DATA } from '../../constants';
import { DATE_FORMAT } from '../_constants';

const { CHECKOUT_FIELDS_KEY } = sesStorage;

/**
 * Sets the credit card options based on the selected credit card and available credit card info.
 * Updates the formData with the paymentProfileId of the selected credit card if it exists.
 *
 * @param {Object} state - The current state object.
 * @param {Object} formValues - The values from the form.
 * @param {Object} formData - The data to be sent in the checkout request.
 */
export const setCreditCardOptions = (state, formValues, formData) => {
  const { creditCardInfo } = state;
  if (!(Number.isInteger(formValues[SELECTED_CREDIT_CARD]) && creditCardInfo)) {
    return;
  }
  const selectedCard = creditCardInfo[formValues[SELECTED_CREDIT_CARD]];
  if (selectedCard?.exist) {
    formData.selectedCC = selectedCard.paymentProfileId;
  }
};

/**
 * Sends a checkout request with the provided form data.
 *
 * @param {Object} formData - The data to be sent in the checkout request.
 * @returns {Promise<{data: Object, error: Object}>} - The response data and error.
 */
export const checkoutRequest = async (formData) => {
  const result = await client().post('/checkout', formData);
  const { data, error } = result.data;

  return { data, error };
};

/**
 * Reloads the page if specific errors are encountered.
 *
 * @param {object} errors - The errors from the checkout response.
 */
export const checkoutReload = (errors) => {
  const errorKeys = Object.keys(errors);
  if (
    errorKeys.includes('shoppingCartEmpty') ||
    errorKeys.includes('usedCoupon')
  ) {
    window.location.reload();
  } else if (errorKeys.includes('redirect')) {
    window.location.href = errors.redirect;
  }
};

/**
 * Handles the checkout request and updates the state based on errors or redirects.
 *
 * @param {Object} form - The form instance.
 * @param {Object} formData - The data to be sent in the checkout request.
 * @param {Function} dispatch - The dispatch function to update the state.
 * @param {string} errorMessage - The error message to be set if there's an error.
 * @returns {Promise<Object>} - The response data from the checkout request.
 */
export const handleCheckoutRequest = async (
  form,
  formData,
  dispatch,
  errorMessage
) => {
  const { data, error } = await checkoutRequest(formData);
  if (error) {
    if (typeof data === 'string') {
      dispatch({
        type: SET_INITIAL_DATA,
        data: { errorMessage: data },
      });
      const paymentMethod = document.getElementById('payment-method');
      scrollTo(paymentMethod, false, 10);
      showNumber();
    } else {
      checkoutReload(data);
      const errorKeys = Object.keys(data);
      form.scrollToField(errorKeys[0], { block: 'center' });
      errorKeys.forEach((index) => {
        form.setFields([
          {
            name: index,
            errors: [data[index][0]],
          },
        ]);
      });
    }
    return Promise.reject(data);
  }
  if (errorMessage) {
    dispatch({
      type: SET_INITIAL_DATA,
      data: { errorMessage: null },
    });
  }
  return Promise.resolve(data);
};

/**
 * Retrieves the checkout fields from session storage and parses them.
 * If a service date is present, it converts it to a dayjs object.
 *
 * @returns {Object} - The parsed checkout fields from session storage.
 */
export const getValuesFromSession = () => {
  const checkoutFields = getSesStorageItem(CHECKOUT_FIELDS_KEY);

  if (!checkoutFields) {
    return {};
  }

  const fields = JSON.parse(checkoutFields);
  if (fields?.service_date) {
    fields.service_date = dayjs(fields.service_date);
  }

  return fields;
};

/**
 * Updates the session storage with the new values, merging them with existing checkout fields.
 *
 * @param {Object} values - The values to be added to session storage.
 */
export const setValuesInSession = (values) => {
  setSesStorageItem(
    CHECKOUT_FIELDS_KEY,
    JSON.stringify({ ...getValuesFromSession(), ...values })
  );
};

/**
 * Retrieves the city and state based on the zip code and updates the form fields.
 *
 * @param {Object} param0 - Contains zip code and setFieldsValue function.
 * @param {string} zipCode - The zip code to lookup.
 * @param {Function} setFieldsValue - Function to update form fields.
 */
export const getCityState = async ({ zipCode, setFieldsValue }) => {
  if (zipCode.length !== ZIP_CODE_LENGTH) return;
  const {
    data: { success, data },
  } = await getCityStateByZip({
    params: { zipCode },
  });
  if (success) {
    const fieldObj = {
      mailing_city: data.city,
      mailing_state: data.state,
    };
    setFieldsValue(fieldObj);
  }
};

/**
 * Returns a string indicating if the address is international or domestic.
 *
 * @param {boolean} international - True if the address is international, false if domestic.
 * @returns {string} - 'international' or 'domestic'.
 */
export const getInternationalOrDomestic = (international) =>
  international ? 'international' : 'domestic';

/**
 * Adds values to the session storage for checkout fields.
 *
 * @param {Object} values - The values to be added to session storage.
 */
export const addValuesInSession = (values) => {
  setSesStorageItem(CHECKOUT_FIELDS_KEY, JSON.stringify(values));
};

/**
 * Removes specific values from session storage for checkout fields.
 *
 * @param {Array<string>} values - The keys of the values to be removed.
 */
export const removeValuesFromSession = (values) => {
  const sessionFields = getValuesFromSession();
  values.forEach((field) => {
    delete sessionFields[field];
  });
  setSesStorageItem(CHECKOUT_FIELDS_KEY, JSON.stringify(sessionFields));
};

/**
 * Sends a tracking event when patient information changes and certain conditions are met.
 *
 * @param {Object} values - The current values of the form fields.
 * @param {Object} allValues - The complete set of form field values.
 */
export const sendTrackWhenPatientChange = (values, allValues) => {
  const patientInfo = [FIRST_NAME, LAST_NAME, B_MONTH, B_DAY, B_YEAR];
  const changedFields = [
    FIRST_NAME,
    LAST_NAME,
    B_MONTH,
    B_DAY,
    B_YEAR,
    EMAIL_ADDRESS,
    NO_EMAIL,
  ];
  if (allValues && changedFields.includes(Object.keys(values)[0])) {
    for (const item of patientInfo) {
      if (!allValues[item]) {
        return;
      }
    }
    if (allValues[EMAIL_ADDRESS] || allValues[NO_EMAIL]) {
      Track('Viewed Checkout Step', { step: 1 });
    }
  }
};

/**
 * Handles the blur event for form fields and performs validation if certain conditions are met.
 *
 * @param {Object} e - The blur event object.
 * @param {Object} form - The form instance.
 * @param {Object} duplicateData - Object containing duplicate data and configuration.
 * @param {Function} checkoutValidation - The validation function to be called.
 */
export const handleOnBlur = (e, form, duplicateData, checkoutValidation) => {
  const types = ['submit', 'checkbox'];
  const fields = [DISCOUNT_CODE, PAYMENT_SYSTEM, USERNAME, PASSWORD, COUPON];
  const values = form.getFieldsValue();
  if (
    e.target.id &&
    !types.includes(e.target.type) &&
    !fields.includes(e.target.id)
  ) {
    if (duplicateData.formOnBlurDisabled) {
      duplicateData.formOnBlurDisabled = false;
      return;
    }
    values[SERVICE_DATE] = values[SERVICE_DATE]?.format(DATE_FORMAT);

    checkoutValidation(values, e.target.id);
  }
};
