import styled from 'styled-components';
import { Select as Component } from 'antd';
import themes from '../Themes';

const SelectComponent = styled(({ ...props }) => <Component {...props} />)`
  .ant-select-selector {
    padding: 0 10px;
    border: 1px solid ${themes.I50} !important;
    border-radius: 0;
    height: 40px;

    &:hover,
    &:focus {
      border-color: ${themes.astral} !important;
    }
  }

  &.ant-select-multiple {
    .ant-select-selector {
      .ant-select-selection-item {
        font-family: ${themes['font-sans-book']} !important;
        font-size: 14px !important;
      }
    }
  }

  &.ant-select-status-error:not(.ant-select-disabled) {
    .ant-select-selector {
      border: 1px solid ${themes['error-color']} !important;
      background-color: ${themes['light-red']} !important;

      .ant-select-selection-item {
        background-color: #ffffff;
        border-color: ${themes['error-color']};
      }

      &:hover {
        border: 1px solid ${themes['error-color']} !important;
      }

      &:focus {
        border: 1px solid ${themes['error-color']} !important;
      }
    }

    // Antd overly complex way of focus when tabbing
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        ant-select-customize-input
      ) {
      .ant-select-selector {
        border: 1px solid ${themes['error-color']} !important;
      }
    }
  }
`;

const Select = (props) => <SelectComponent {...props} />;

/** @component */
export default Select;
