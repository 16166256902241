import React from 'react';
import propTypes from 'prop-types';

import { InputNumber as Component } from 'antd';
import styled from 'styled-components';
import themes from 'md-styled-components/Themes';
import useDisableScrollOnWheel from './useDisableScrollOnWheel';
import { defaultStyles } from './Input';

const StyledInputPrice = styled(Component)`
  height: ${themes['input-height-base']} !important;
  ${defaultStyles}
`;

const Price = React.forwardRef(({ allowClear, ...props }, ref) => {
  useDisableScrollOnWheel();
  return <StyledInputPrice {...props} ref={ref} />;
});

Price.propTypes = {
  formatter: propTypes.func,
  parser: propTypes.func,
  min: propTypes.number,
};

Price.defaultProps = {
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value?.replace(/\$\s?|(,*)/g, ''),
  min: 0,
  precision: 2,
};

export default Price;
