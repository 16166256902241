const trainingRoutes = {
  controller: 'Groups',
  action: 'trainingVideos',
};
const pricingGroupManager = {
  controller: 'PricingGroups',
  action: 'index',
};

const campaignManager = {
  permission: 'CampaignManager',
  controller: '',
  action: '',
};

const privateRoutes = {
  '/training': trainingRoutes,
  '/training-what-is-mdsave': trainingRoutes,
  '/training-register-ehr': trainingRoutes,
  '/training-when-to-offer': trainingRoutes,
  '/training-how-to-purchase': trainingRoutes,
  '/training-care-packages': trainingRoutes,
  '/training-ways-to-pay': trainingRoutes,
  '/training-patient-deductible': trainingRoutes,
  '/training-financing': trainingRoutes,
  '/training-purchase-scenario': trainingRoutes,
  '/training-redemption-scenario': trainingRoutes,
  '/third-party-procedure-descriptions': {
    controller: 'ThirdPartyNetworks',
    action: 'getDescriptions',
  },
  '/site': { controller: 'Site', action: 'index' },
  '/bbcms': { controller: 'Site', action: 'index' },
  '/site/claims': { controller: 'Claims', action: 'getClaims' },
  '/site/billingProviderConfiguration': {
    controller: 'BillingProviderConfiguration',
    action: 'getBPConfiguration',
  },
  '/site/partyDetailsNpiNumbers': {
    controller: 'PartyDetailsNpiNumbers',
    action: 'getPartyDetailsNpiNumbers',
  },
  // TO DO: change this when getEdit action is ready
  '/site/claims/edit': { controller: 'Claims', action: 'getClaims' },
  '/groups/reportPage': {
    controller: 'groups',
    action: 'reportPage',
  },
  '/groups/savedCarts': { controller: 'Groups', action: 'savedCarts' },
  '/site/groupSystem': {
    module: 'cmsApi',
    controller: 'Systems',
    action: 'hospitalSystems',
  },
  '/site/deductibleCheckerLog': {
    module: 'api',
    controller: 'DeductibleChecker',
    action: 'getListData',
  },
  '/site/invoices': {
    module: 'cmsApi',
    controller: 'Invoices',
    action: 'companyInvoices',
  },
  '/customer': {
    module: 'cmsApi',
    controller: 'Customers',
    action: 'getCustomers',
  },
  '/site/viewReports': { controller: 'Groups', action: 'reportsGetListData' },
  '/third-party-provider-fee': {
    controller: 'ThirdPartyNetworks',
    action: 'getFees',
  },
  '/third-party-cpt-codes': {
    controller: 'ThirdPartyNetworks',
    action: 'getCptCodes',
  },
  '/third-party-procedure-cpt-codes': {
    controller: 'ThirdPartyNetworks',
    action: 'getProcedureCptCodes',
  },
  '/third-party-networks': {
    controller: 'ThirdPartyNetworks',
    action: 'getThirdParties',
  },
  '/log-crypt': { module: 'cmsApi', controller: 'LogCrypt', action: 'index' },
  '/campaign-manager': campaignManager,
  '/campaign/create': campaignManager,
  '/pricingGroups/create': pricingGroupManager,
  '/pricingGroups/update': pricingGroupManager,
  '/campaign/update': campaignManager,
  '/third-party-provider-name-mappings': {
    controller: 'ThirdPartyNetworks',
    action: 'getProviderNameMappings',
  },
  '/groups/groupUsers': { controller: 'groups', action: 'getGroupUsers' },
  '/groups/vouchers': { controller: 'groups', action: 'getVouchers' },
  '/company/invoicePage': { controller: 'Portal', action: 'invoicePage' },
  '/company/companyUsers': {
    controller: 'Portal',
    action: 'getPortalUsers',
  },
  '/company/companyUsers/create': {
    controller: 'CompanyUsers',
    action: 'createCompanyUser',
  },
  '/company/companyUsers/edit': {
    controller: 'CompanyUsers',
    action: 'editCompanyUser',
  },
  '/group-users': {
    controller: 'GroupUsers',
    action: 'getUsers',
  },
  '/MedicalProvider/view': {
    controller: 'MedicalProvider',
    action: 'view',
  },
  '/site/createGroupSystem': {
    module: 'cmsApi',
    controller: 'Systems',
    action: 'createHospitalSystemPage',
  },
  '/site/updateGroupSystem': {
    module: 'cmsApi',
    controller: 'Systems',
    action: 'updateGroupSystemPage',
  },
  '/sso-configuration': {
    controller: 'SsoConfiguration',
    action: 'index',
  },
  '/blacklist': {
    controller: 'Blacklist',
    action: '',
  },
  '/groups/add-providers-via-excel': {
    controller: 'Groups',
    action: 'providers',
  },
};

export default privateRoutes;
