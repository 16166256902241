import React from 'react';
import propTypes from 'prop-types';

import { Form, Input } from 'md-styled-components-v2';

const Field = ({
  name,
  type,
  placeholder,
  forcePlaceholder,
  decorator,
  breakpoint,
  isRequired,
  initialValue,
  isPhi = false,
  disabled,
  ...props
}) => {
  const fieldName = props.fieldName || props.label || placeholder;
  const initialProps = {
    initialValue: initialValue || '',
    validateTrigger: ['onBlur', 'onChange'],
  };

  let inputPlaceholder = placeholder;
  let itemLabel = props.label;
  if (!decorator) {
    decorator = {};
  }

  if (!isRequired) {
    inputPlaceholder = `${inputPlaceholder} (Optional)`;
    itemLabel = `${itemLabel} (Optional)`;
  } else {
    decorator.rules = [
      ...(decorator.rules || []),
      { required: true, message: `${fieldName} cannot be blank.` },
    ];
  }

  return (
    <Form.Item
      {...initialProps}
      {...props}
      label={breakpoint && itemLabel}
      name={name}
      {...decorator}
    >
      {props.children || (
        <Input
          name={name}
          classNames={{ input: isPhi ? 'phi' : '' }}
          placeholder={!breakpoint || forcePlaceholder ? inputPlaceholder : ''}
          type={type}
          disabled={disabled}
        />
      )}
    </Form.Item>
  );
};

Field.defaultProps = {
  placeholder: '',
  label: '',
  type: 'text',
  isRequired: false,
  /** compares screen size */
  breakpoint: true,
};

Field.propTypes = {
  name: propTypes.string.isRequired,
  type: propTypes.string,
  placeholder: propTypes.string,
  label: propTypes.oneOfType([propTypes.string, propTypes.node]),
  decorator: propTypes.object,
  isRequired: propTypes.bool,
  breakpoint: propTypes.bool,
  isPhi: propTypes.bool,
  placeholderOrLabel(props, propName, componentName) {
    if (!props.placeholder && !props.label) {
      return new Error(
        'One of these is required `placeholder` or `label`' +
          ` \`${componentName}\`. Validation failed.`
      );
    }
    return null;
  },
};

export default Field;
