import React, { useEffect } from 'react';
import usePhoneNumber, { showNumber } from 'hooks/PhoneNumber';
import './styles/index.less';
import './styles/a-b-test.less';
import NavbarMobileProvider from 'components/NavbarSearch/_context/NavbarMobileProvider';
import { clearSesStorageItem } from 'utils/storage';
import { sesStorage } from 'constants/index';
import PageContainerLayout from './PageContaienerLayout';
import PageDirectNavbar from './PageComponents/PageDirectNavbar';
import ShowAlert from './Modals/ShowAlert';

const { CHECKOUT_FIELDS_KEY, DUPLICATE_FIELDS } = sesStorage;

export default React.memo((props) => {
  const { navbarFixed } = props;
  usePhoneNumber();

  useEffect(() => {
    clearSesStorageItem(CHECKOUT_FIELDS_KEY);
    clearSesStorageItem(DUPLICATE_FIELDS);
    showNumber();
  }, []);

  return (
    <NavbarMobileProvider>
      <PageDirectNavbar navbarFixed={navbarFixed} pageType={props.pageType} />
      <PageContainerLayout {...props} pageType={props.pageType} />
      <ShowAlert />
    </NavbarMobileProvider>
  );
});
