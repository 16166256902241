import React from 'react';
import propTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import styled, { css } from 'styled-components';
// eslint-disable-next-line import/no-unresolved
import IconSet from './selection.json';

const IconWrapper = styled.span`
  display: inline-block !important;
  text-align: center;
  line-height: 0;
  ${(props) =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight}px;
    `}
`;

const Icon = React.memo(
  ({ type, size, color, library, className, iconClassName, ...props }) => {
    if (library === 'mds-old-icons') {
      return <i className={type} style={{ fontSize: size, color }} />;
    }

    return (
      <IconWrapper className={className} {...props}>
        <IcomoonReact
          color={color}
          iconSet={IconSet}
          size={size}
          data-icon-tye={type}
          icon={!type.indexOf('icon-', 4) ? `icon-${type}` : type}
          className={iconClassName}
        />
      </IconWrapper>
    );
  }
);

Icon.propTypes = {
  /** ClassName for the icon */
  iconClassName: propTypes.string,
  /** Color of the icon */
  color: propTypes.string,
  /** Type of the icon */
  type: propTypes.string,
  /** Icon size */
  size: propTypes.oneOfType([propTypes.number, propTypes.string]),
  /** Library Type - mds or mds-old-icons */
  library: propTypes.oneOf(['mds', 'mds-old-icons']),
};

Icon.defaultProps = {
  color: '',
  type: '',
  size: 60,
  library: 'mds',
};

/** @component */
export default Icon;
