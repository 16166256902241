import { useState } from 'react';
import { Spin } from 'md-styled-components';
import { AutoComplete, Form, Input } from 'md-styled-components-v2';
import PropTypes from 'prop-types';
import Label from '../../../Form/Label';
import { useInputChange } from './hooks';
import { renderMenu } from './renderMenu';

const InsuranceProvider = ({
  handleSelectProvider,
  SelectedProvider,
  hasValidate = false,
  initialInputValue = '',
  className = 'mb--0',
}) => {
  const [inputValue, setInputValue] = useState(initialInputValue);
  const { onInputChange, providersList, loading, isTyped } = useInputChange(
    setInputValue,
    handleSelectProvider
  );

  const emptyLabel =
    'Sorry, we could not find a match for that insurance provider';
  const noData = providersList !== null && isTyped && !providersList.length;
  const dataSource = (isTyped && providersList) || [];

  const onSelect = (value, item) => {
    if (value) {
      setInputValue(value);
      handleSelectProvider(value, item);
    }
  };

  return (
    <Form.Item
      label='Insurance Provider'
      className={className}
      name='insuranceProvider'
      validateTrigger='onBlur'
      validateFirst
      rules={
        hasValidate
          ? [
              {
                required: true,
                message: 'Insurance Provider cannot be blank.',
              },
              {
                validator(_, value) {
                  if (value && !SelectedProvider) {
                    return Promise.reject(
                      new Error('Select an option from the dropdown.')
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]
          : []
      }
    >
      <AutoComplete
        id='provider_name'
        onSelect={onSelect}
        notFoundContent={loading ? <Spin /> : noData && emptyLabel}
        options={renderMenu(dataSource, inputValue)}
      >
        <Input
          onChange={onInputChange}
          data-testid='provider_name'
          className='phi'
        />
      </AutoComplete>
    </Form.Item>
  );
};

InsuranceProvider.propTypes = {
  handleSelectProvider: PropTypes.func,
  SelectedProvider: PropTypes.string,
  initialInputValue: PropTypes.string,
  hasValidate: PropTypes.bool,
  className: PropTypes.string,
};

export default InsuranceProvider;
