import React, { useEffect } from 'react';
import { Link } from 'md-styled-components-v2';
import { showNumber } from 'hooks/PhoneNumber';
import { common } from '../../constants';

const { supportPhoneNumber } = common;
export default React.memo(
  ({ className = '', renderCustomText, loadNumber, ...props }) => {
    useEffect(() => {
      if (loadNumber) {
        showNumber();
      }
    }, [loadNumber]);
    return (
      <Link
        to={`tel:+${supportPhoneNumber}`}
        reloadDocument
        underline={props?.underline}
        className={`phone-number ${className}`}
        size='large'
        {...props}
      >
        {renderCustomText
          ? renderCustomText(supportPhoneNumber)
          : supportPhoneNumber}
      </Link>
    );
  }
);
