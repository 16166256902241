import React from 'react';
import styled, { css } from 'styled-components';
import { Link as Component } from 'react-router-dom';
import propTypes from 'prop-types';
import Theme from 'md-styled-components/Themes';
import Icon from 'md-styled-components/Icon';
import standartType from './_types/standart';
import standardWithLogoType from './_types/standardWithLogo';
import whiteType from './_types/white';
import dangerousType from './_types/dangerous';
// buttonTypes
import buttonCommonStyles from '../Button/_types/common';
import primaryStyles from '../Button/_types/primary';
import secondaryStyles from '../Button/_types/secondary';
import tertiaryStyles from '../Button/_types/tertiary';
import dangerousStyles from '../Button/_types/dangerous';
import ghostSTyles from '../Button/_types/ghost';
import dangerousTertiaryStyles from '../Button/_types/dangerousTertiary';
import tertiaryDirectStyles from '../Direct/Button/_types/tertiary';
import primaryDirectStyles from '../Direct/Button/_types/primary';
import secondaryDirectStyles from '../Direct/Button/_types/secondary';
import dangerousDirectStyles from '../Direct/Button/_types/dangerous';
import dangerousTertiaryDirectStyles from '../Direct/Button/_types/dangerousTertiary';
import ghostDirectStyles from '../Direct/Button/_types/ghost';

const fontSizes = {
  xsmall: {
    'font-size': '12px',
    'line-height': '16px',
  },
  small: {
    'font-size': '14px',
    'line-height': '18px',
  },
  large: {
    'font-size': '16px',
    'line-height': '22px',
  },
};

const sizes = {
  xsmall: 5,
  small: 7,
  middle: 9,
  large: 14,
};
// Note: Link component is interconnected with button styles.
// Use asButton props and button type names to achieve button link links

const Link = styled(
  React.forwardRef((props, ref) => <Component {...props} ref={ref} />)
)`
  font-family: ${Theme['font-sans-medium']};
  font-weight: ${Theme['font-weight-bold']};
  text-decoration: none;

  .link-icon-margin-left {
    margin-left: 6px;
  }

  .link-icon-margin-right {
    margin-right: 6px;
  }

  ${(props) =>
    props.underline &&
    css`
      &&,
      &&:active,
      &&:focus,
      &&:hover {
        text-decoration: underline;
      }
    `}

  ${(props) => css`
    ${fontSizes[props.size]}
  `}
 
  ${(props) => props.type === 'standart' && standartType}
  ${(props) => props.type === 'standardWithLogo' && standardWithLogoType}
  ${(props) => props.type === 'white' && whiteType}
  ${(props) => props.type === 'dangerous' && dangerousType}
  ${(props) => props.type === 'dangerous-tertiary' && dangerousTertiaryStyles}

  ${(props) =>
    props.size &&
    props.asButton &&
    css`
      display: inline-block;
      border-radius: ${props.type.includes('direct') ? '4px' : '100px'};
      ${buttonCommonStyles(sizes[props.size])}

      ${props.type === 'primary' && primaryStyles}

    ${props.type === 'secondary' && secondaryStyles}

    ${props.type === 'tertiary' && tertiaryStyles}
      
    ${props.type === 'dangerous' && dangerousStyles}

    ${props.type === 'ghost' && ghostSTyles}

    ${props.type === 'tertiary-direct' && tertiaryDirectStyles}
      
    ${props.type === 'secondary-direct' && secondaryDirectStyles}
      
    ${props.type === 'primary-direct' && primaryDirectStyles}
      
    ${props.type === 'ghost-direct' && ghostDirectStyles}
      
    ${props.type === 'dangerous-direct' && dangerousDirectStyles}
      
    ${props.type === 'dangerous-tertiary-direct' &&
      dangerousTertiaryDirectStyles}
    `}
  ${(props) =>
    props.block &&
    css`
      display: block;
    `}
`;

const arrangeElements = (linkTxt, { type, size: iconSize, alignRight }) => {
  // iconSize prop is for managing icons with inconsistent spacings
  const className = !alignRight
    ? 'link-icon-margin-right'
    : 'link-icon-margin-left';
  const leftLinkTxt = !alignRight ? '' : linkTxt;
  const rightLinkTxt = !alignRight ? linkTxt : '';

  return (
    <>
      {type && (
        <>
          {leftLinkTxt}
          <span className={className}>
            <Icon type={type} size={iconSize || 16} />
          </span>
        </>
      )}
      {rightLinkTxt}
    </>
  );
};

const LinkIcon = ({ icon, underline, block, ...props }) => (
  <>
    <Link {...props} underline={underline} block={block}>
      {arrangeElements(props.children, icon)}
    </Link>
  </>
);

LinkIcon.propTypes = {
  type: propTypes.oneOf([
    'standart',
    'standardWithLogo',
    'white',
    'dangerous',
    'primary',
    'secondary',
    'tertiary',
    'dangerous',
    'dangerous-tertiary',
    'tertiary-direct',
  ]),
  size: propTypes.string,
  icon: propTypes.shape({
    type: propTypes.string,
    size: propTypes.number,
    alignRight: propTypes.bool,
  }),
  to: propTypes.oneOfType([propTypes.string, propTypes.object, propTypes.func])
    .isRequired,
  underline: propTypes.bool,
  asButton: propTypes.bool,
};

LinkIcon.defaultProps = {
  underline: false,
  asButton: false,
  size: 'small',
  type: 'standart',
  icon: {},
};

/** @component */
export default LinkIcon;
