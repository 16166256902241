// This file contains all the field names used in the application.
export const FIRST_NAME = 'first_name';
export const LAST_NAME = 'last_name';
export const MOBILE_PHONE = 'mobile_phone';
export const PLAN_OF_CARE_IDENTIFIER = 'plan_of_care_identifier';
export const BEHALF_OF_A_PARTNER = 'behalfOfAPartner';
export const SELECTED_CREDIT_CARD = 'selectedCreditCard';
export const MAILING_ADDRESS = 'mailing_address';
export const MAILING_ZIP_CODE = 'mailing_zip_code';
export const MAILING_CITY = 'mailing_city';
export const MAILING_STATE = 'mailing_state';
export const MAILING_COUNTRY = 'mailing_country';
export const IS_INTERNATIONAL_VIEW = 'isInternationalView';
export const APPOINTMENT_TYPE = 'appointment_type';
export const ICD_CODE = 'icd_code';
export const SERVICE_DATE = 'service_date';
export const REFERRING_DOCTOR = 'referringDoctor';
export const REFERRING_DOCTOR_HIDE = 'referringDoctorHide';
export const CALL_IDENTIFIER = 'call_identifier';
export const NO_SALESFORCE_IDENTIFIER = 'noSalesforceIdentifier';
export const INSURANCE_PARTNER = 'insurance_partner';
export const INSURANCE_MEMBER_ID = 'insurance_member_id';
export const INSURANCE_GROUP_ID = 'insurance_group_id';
export const INSURANCE_PROVIDER_DISPLAY_NAME = 'insuranceProvider';
export const CARE_CREDIT_PROMOTION = 'care_credit_promotion';
export const CLERK_NAME = 'clerk_name';
export const CHECK_CONFIRM = 'checkConfirm';
export const PAYMENT_SYSTEM = 'paymentSystem';
export const MULTIPLE_PAYMENT_TYPE = 'multiplePaymentType';
export const DISCOUNT_CODE = 'discountCode';
export const COUPON = 'coupon';
export const APPLY_COUPON = 'apply_coupon';
export const EMAIL_ADDRESS = 'email_address';
export const USERNAME = 'username';
export const PASSWORD = 'password';
export const NO_EMAIL = 'noEmail';
export const PROXY_PATIENT_ID = 'proxy_patient_id';
export const IS_PURCHASE_FOR_GUEST = 'isPurchaseForGuest';
export const PORTAL = 'portal';
export const B_DAY = 'b_day';
export const B_YEAR = 'b_year';
export const B_MONTH = 'b_month';
export const SUBSCRIBE = 'subscribe';
export const SUBSCRIBE_SMS_MESSAGES = 'subscribe_sms_messages';
export const ESTIMATED_PRICE = 'estimatedPrice';
export const EMAIL_LOCKED = 'emailLocked';

// Duplicate account field's names
export const IS_ACTIVE_DUPLICATE = 'isActiveDuplicate';
export const IS_GUEST_DUPLICATE = 'isGuestDuplicate';
export const EMAIL_TBL_USER_ID = 'emailTblUserId';
export const GUEST_CUSTOMER_ID = 'guestCustomerId';
export const ACTIVE_CUSTOMER_ID = 'activeCustomerId';
export const DONT_SAVE_CUSTOMER = 'dontSaveCustomer';
export const DISABLE_VALIDATION = 'disableValidation';
export const DUPLICATE_FOUND = 'duplicateFound';
export const DUPLICATE_EMAIL_MODAL = 'duplicateEmailModal';
export const DUPLICATE_MESSAGE_MODAL = 'duplicateMessageModal';
export const DUPLICATE_MESSAGE_PROXY_MODAL = 'duplicateMessageProxyModal';

// Credit Card field's names
export const CARDHOLDER_FIRST_NAME = 'cardholderFirstName';
export const CARDHOLDER_LAST_NAME = 'cardholderLastName';
export const CREDIT_CARD = 'creditCard';
export const CC_MM = 'cc_mm';
export const CC_YY = 'cc_yy';
export const EXP_DATE = 'expDate';
export const CC_EXP_DATE = 'cc_exp_date';
export const CC_CCV = 'ccv';
export const ZIP_CODE = 'zip_code';
export const AMOUNT = 'amount';
export const HIDDEN_CREDIT_CARD = 'hiddenCreditCard';
export const HIDDEN_CC_CCV = 'hiddenCCV';
export const CASH_AMOUNT = 'cash';
export const PROCEED_CASH = 'proceedCash';
export const PROCEED_CARE_CREDIT = 'proceedCareCredit';
export const CARE_CREDIT = 'careCredit';
export const INVOICE_AMOUNT = 'invoiceAmount';
export const SHOW_INVOICE_PAYMENT = 'showInvoicePayment';
export const MULTIPLE_CC_AMOUNT = 'multipleCCAmount';
export const CUSTOMER_CC = 'customerCC';
export const CUSTOMER_BANK_ACCOUNTS = 'customerBankAccounts';
export const CARE_CREDIT_AMOUNT = 'careCreditAmount';
export const DUPLICATE_CARD_ERROR = 'duplicateCardError';
export const CREDIT_CARD_COUNT = 'creditCardCount';
