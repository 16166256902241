import PrivateRoute from 'components/PrivateRoute';
import React, { lazy } from 'react';
import { Route } from 'react-router-dom';
import SsoConfigurationCreate from './SsoConfiguration/Create';
import SsoConfigurationUpdate from './SsoConfiguration/Update';

const HospitalSystem = lazy(() => import('./HospitalSystemPage'));
const SitePage = lazy(() => import('./Site'));
const DataImport = lazy(() => import('./DataImport'));
const HospitalSystemCreate = lazy(() => import('./HospitalSystemPage/Create'));
const HospitalSystemUpdate = lazy(() => import('./HospitalSystemPage/Update'));
const Claims = lazy(() => import('./Claims/ClaimsOverview'));
const BillingProviderPage = lazy(() =>
  import('./BillingProviderConfiguration/BillingProviderOverview')
);
const PartyDetailsNpiNumbersPage = lazy(() =>
  import('./PartyDetailsNpiNumbers/PartyDetailsNpiNumbersOverview')
);
const ClaimsEdit = lazy(() => import('./Claims/ClaimsEdit'));
const DeductibleCheckerLog = lazy(() => import('./DeductibleCheckerLog'));
const Invoices = lazy(() => import('./InvoicesPage'));
const Patients = lazy(() => import('./PatientsPage'));
const SentReports = lazy(() => import('./SentReportsPage'));
const CampaignManager = lazy(() => import('pages/AdminTools/CampaignManager'));
const ManageCampaign = lazy(() =>
  import('pages/AdminTools/CampaignManager/_partials/Manage')
);
const ManagePricingGroup = lazy(() =>
  import('pages/AdminTools/PricingGroups/_partials/Manage')
);
const ThirdPartyNetworks = lazy(() =>
  import('pages/AdminTools/ThirdPartyNetworks')
);
const ThirdPartyProcedureDescriptions = lazy(() =>
  import('pages/AdminTools/ThirdPartyProcedureDescriptions')
);
const ProvidersFees = lazy(() =>
  import('pages/AdminTools/ThirdPartyProviderFees')
);
const ProceduresCPTCodes = lazy(() =>
  import('pages/AdminTools/ThirdPartyProceduresCPTCodes')
);
const CPTCodes = lazy(() => import('pages/AdminTools/ThirdPartyCPTCodes'));
const ProviderNameMappings = lazy(() =>
  import('pages/AdminTools/ThirdPartyProviderNameMappings')
);
const Crypt = lazy(() => import('pages/AdminTools/Crypt'));
const CreateCompanyUsers = lazy(() => import('./Companies/CreateCompanyUsers'));
const CompanyUsers = lazy(() =>
  import('pages/AdminTools/Companies/CompanyUsers')
);
const AllGroupUsers = lazy(() => import('pages/AdminTools/GroupUsers'));
const Provider = lazy(() => import('pages/Provider'));
const SsoConfiguration = lazy(() => import('pages/AdminTools/SsoConfiguration'));
const BlacklistIps = lazy(() => import('pages/AdminTools/BlacklistIps'));

const adminRoutes = () => (
  <Route element={<PrivateRoute />}>
    <Route path='site' element={<SitePage />} />
    <Route path='bbcms' element={<SitePage />} />
    <Route path='site/groupSystem' element={<HospitalSystem />} />
    <Route path='site/createGroupSystem' element={<HospitalSystemCreate />} />
    <Route path='site/updateGroupSystem' element={<HospitalSystemUpdate />} />
    <Route
      path='site/deductibleCheckerLog'
      element={<DeductibleCheckerLog />}
    />
    <Route path='site/invoices' element={<Invoices />} />
    <Route path='customer' element={<Patients />} />
    <Route path='site/claims' element={<Claims />} />
    <Route
      path='site/billingProviderConfiguration'
      element={<BillingProviderPage />}
    />
    <Route
      path='site/partyDetailsNpiNumbers'
      element={<PartyDetailsNpiNumbersPage />}
    />
    <Route path='site/claims/edit' element={<ClaimsEdit />} />
    <Route path='site/viewReports' element={<SentReports />} />
    <Route path='site/data-import' element={<DataImport />} />
    <Route path='third-party-provider-fee' element={<ProvidersFees />} />
    <Route path='third-party-cpt-codes' element={<CPTCodes />} />
    <Route
      path='third-party-procedure-cpt-codes'
      element={<ProceduresCPTCodes />}
    />
    <Route path='third-party-networks' element={<ThirdPartyNetworks />} />
    <Route
      path='third-party-procedure-descriptions'
      element={<ThirdPartyProcedureDescriptions />}
    />
    <Route path='campaign-manager' element={<CampaignManager />} />
    <Route path='campaign/create' element={<ManageCampaign />} />
    <Route path='campaign/update' element={<ManageCampaign />} />
    <Route path='pricingGroups/create' element={<ManagePricingGroup />} />
    <Route path='pricingGroups/update' element={<ManagePricingGroup />} />
    <Route
      path='third-party-provider-name-mappings'
      element={<ProviderNameMappings />}
    />
    <Route path='log-crypt' element={<Crypt />} />
    <Route
      path='company/companyUsers/create'
      element={<CreateCompanyUsers />}
    />
    <Route path='company/companyUsers/edit' element={<CreateCompanyUsers />} />
    <Route path='company/companyUsers' element={<CompanyUsers />} />
    <Route path='group-users' element={<AllGroupUsers />} />
    <Route path='MedicalProvider/view' element={<Provider />} />
    <Route path='sso-configuration' element={<SsoConfiguration />} />
    <Route path='sso-configuration/create' element={<SsoConfigurationCreate />} />
    <Route path='sso-configuration/update' element={<SsoConfigurationUpdate />} />
    <Route path='blacklist' element={<BlacklistIps />} />
  </Route>
);

export default adminRoutes;
