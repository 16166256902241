import React from 'react';
import styled from 'styled-components';
import { Breadcrumb as Component } from 'antd';
import propTypes from 'prop-types';
import themes from '../Themes';

const BreadcrumbWrapper = styled(Component)`
  ol > li {
    color: ${themes['green-slate']};
    font-family: ${themes['font-sans-medium']};
    font-size: ${themes['text-2-size']};

    .ant-breadcrumb-link {
      color: ${themes['green-slate']};
      line-height: 21px;
      padding: 0;
    }

    a {
      font-size: inherit;
      color: ${themes['primary-main-color']};
      position: relative;
      border: none;
      line-height: 21px;
      height: auto;
      padding: 0 !important;
      margin-inline: 0;

      &:hover {
        color: ${themes['primary-main-color']};
        background-color: transparent;
      }

      &:focus:after {
        bottom: -4px;
        height: 2px;
      }
    }

    &:active:after,
    & > a:hover:after,
    & > a:focus:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -3px;
      width: 100%;
      height: 1px;
      background: ${themes['primary-main-color']};
    }

    &:active:after {
      height: 0;
    }

    &:focus {
      outline-width: 0;
    }

    &:active:after {
      background: ${themes['green-slate']};
    }
  }

  .ant-breadcrumb-separator {
    color: ${themes.I60} !important;
  }
`;

const Breadcrumb = (props) => <BreadcrumbWrapper {...props} />;

Breadcrumb.propTypes = {
  /** Custom item renderer */
  itemRender: propTypes.func,
  /** Routing parameters */
  params: propTypes.object,
  /** The routing stack information of router */
  items: propTypes.arrayOf(propTypes.object),
  /** Custom separator */
  separator: propTypes.oneOfType([propTypes.string, propTypes.element]),
};

Breadcrumb.defaultProps = {
  itemRender: () => undefined,
  params: {},
  items: [],
  separator: '/',
};

/** @component */
Breadcrumb.Item = Component.Item;
Breadcrumb.Separator = Component.Separator;
export default Breadcrumb;
